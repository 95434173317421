import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./Pricing.css";

const Pricing = () => {
  const [isGROWToggled, setIsGROWToggled] = useState(false);
  const [isGROUPToggled, setIsGROUPToggled] = useState(false);
  
  const navigate = useNavigate();

  const toggleGROW = () => {
    setIsGROWToggled(!isGROWToggled);
  };

  const toggleGROUP = () => {
    setIsGROUPToggled(!isGROUPToggled);
  };

  const demobtn = () => {
    navigate("/request-a-demo");
  };

  const upgradebtn2 = () => {
    window.location.href = "https://buy.stripe.com/dR614vcKkdu92SQeUV";
  };

  const upgradebtn3 = () => {
    navigate("/request-a-demo");
  };

  return (
    <>
      <div className="pricing page-id-03">
        <div className="pricingrow01">
          <div className="container row3text">
            <h2>SELL QUICKER AND MAKE BETTER DECISIONS</h2>
            <button className="btn2" onClick={demobtn}>
              Request a Demo
            </button>
          </div>
        </div>
        <div className="homerow4 pricingrow2">
          <div className="bgrow4">
            <div className="container row4text">
              <div className="priceboxes01">
                <div className="pricb1 priceb2">
                  <h3>GROW</h3>
                  <p className="pr">US$960 per year</p>
                  <button className="btn3" onClick={upgradebtn2}>
                    START TRIAL
                  </button>
                  <ul className="list01">
                    <li>10 user accounts</li>
                    <li>Contactless booking</li>
                    <li>Complete booking platform</li>
                    <li>Upsell services and retail</li>
                    <li>Dedicated retail shop</li>
                  </ul>
                  <div className="buttonaling">
                    <button
                      className="tog"
                      onClick={toggleGROW}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        fontSize: "16px",
                      }}
                    >
                      {isGROWToggled ? "Hide All Features" : "See All Features"}
                    </button>
                  </div>
                  {isGROWToggled && (
                    <div style={{ marginTop: "10px" }}>
                      <ul className="list01">
                        <li>Retail inventory management</li>
                        <li>Waitlist management</li>
                        <li>Guest profiling</li>
                        <li>Business pulse check</li>
                        <li>Live reporting suite</li>
                        <li>30+ live KPIs</li>
                        <li>Online payments</li>
                        <li>Integrations</li>
                        <li>Online consultation (Q1, 2025)</li>
                      </ul>
                    </div>
                  )}
                </div>

                <div className="pricb1">
                  <h3>GROUP</h3>
                  <p className="pr pde0" style={{ opacity: 0 }}>
                    Includes 2 users
                  </p>
                  <button className="btn3" onClick={upgradebtn3}>
                    REQUEST CONSULTATION
                  </button>
                  <ul className="list01">
                    <li>Unlimited users</li>
                    <li>Group users</li>
                    <li>Regional users</li>
                    <li>Multi-spa access</li>
                    <li>Real-time group dashboard</li>
                  </ul>
                  <div className="buttonaling">
                    <button
                      className="tog"
                      onClick={toggleGROUP}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        fontSize: "16px",
                      }}
                    >
                      {isGROUPToggled ? "Hide All Features" : "See All Features"}
                    </button>
                  </div>
                  {isGROUPToggled && (
                    <div style={{ marginTop: "10px" }}>
                      <ul className="list01">
                        <li>Live group KPIs</li>
                        <li>Live analytics dashboard</li>
                        <li>Customer demographics</li>
                        <li>Feedback integrations</li>
                        <li>Consultation analysis (Q1, 2025)</li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="homrrow7 pricingrow3">
          <div className="container">
            <div className="scbox">
              <div className="scle1"></div>
              <div className="rig">
                <h2>
                  Schedule a <br />
                  <span>demo today!</span>
                </h2>
                <p>
                  See spa-tx in action and sign up for a personalized demo with
                  one of our technical experts.
                </p>
                <button className="btn2" onClick={demobtn}>
                  Request a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
