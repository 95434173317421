
import "./Home.css";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
// import Homeproductslider from '../components/Homeproductslider';
import Hometestimonials from "../components/Hometestimonials";

const Home = () => {
  const [isGROWToggled, setIsGROWToggled] = useState(false);
  const [isGROUPToggled, setIsGROUPToggled] = useState(false);
  const toggleGROW = () => {
    setIsGROWToggled(!isGROWToggled);
  };

  const toggleGROUP = () => {
    setIsGROUPToggled(!isGROUPToggled);
  };
  const navigate = useNavigate(); // Initialize useNavigate hook
  const trialbtn = () => {
    window.location.href = "https://app.spa-tx.com/register";
  };
  const demobtn = () => {
    navigate("/request-a-demo"); // Navigate to the desired route
  };
  /* 
  const upgradebtn1 = () => {
    window.location.href = "https://buy.stripe.com/9AQ7sTfWw89PdxufYY";
  }; */
  const upgradebtn2 = () => {
    window.location.href = "https://buy.stripe.com/dR614vcKkdu92SQeUV";
  };
  const upgradebtn3 = () => {
    navigate("/request-a-demo"); // Navigate to the desired route
  };
  return (
    <>
      <div className="home page-id-01">
        <div className="homebanner">
          <div className="container homeinner">
            <div className="banleft">
              <h1>EQUIPPING SPA'S TO OPTIMIZE GROWTH </h1>
              <h2>
                <span class="blocktext">LEARN</span>{" "}
                <span class="blocktext">OPTIMIZE</span>{" "}
                <span class="blocktext">GROW</span>
              </h2>
              <p>
                We help you to make more sales and make better business
                decisions for your spa.
              </p>
              <button onClick={trialbtn}>Start a Free Trial </button>
            </div>
            <div className="banright">
            {/*   <img src="/img/up-banner.png" alt="Banner" /> */}
            </div> 
          </div>

          {/* <div className="container homebtm">
     <h2>What is SpaTX? </h2>
          <h3>Explained in 2 minutes</h3> 
          <div className="video-container">
            <iframe
                src="https://www.youtube.com/embed/XAqGCsirDwU"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="What is SpaTX?"
            />
        </div>
        </div> */}
        </div>
        <div className="homerow02">
          <div className="bgrow02">
            <div className="container">
              <div className="homerowabouttext">
                <h2>SPATX BENEFITS</h2>
              </div>
{ /*new */}
<div className="feature-container">
  <div className="feature-box hover-effect">
    <div className="feature-content">
      <div className="feature-image"><div className="border-image"><img src="/img/icon1.png" alt="icon1" /></div></div>
      <div className="feature-title">
        <h2>DRIVE SALES</h2>
        <h3>Increase Revenue</h3>
      </div>
      <ul className="dropdown-list">
        <li>Intuitive booking calendar with
        mobile application</li>
        <li>Built in revenue drivers (upsell,
          retail)</li>
        <li>Direct online booking</li>
        <li>Yield management pricing
        model</li>
      </ul>
    </div>
  </div>
  
  <div className="feature-box hover-effect">
    <div className="feature-content">
      <div className="feature-image"><div className="border-image"><img src="/img/icon2.png" alt="icon2" /></div></div>
      <div className="feature-title">
        <h2>EQUIP MANAGERS</h2>
        <h3>Build Control</h3>
      </div>
      <ul className="dropdown-list">
        <li>In-moment daily dashboard</li>
        <li>Pre-defined product knowledge</li>
        <li>Auto-waitlist optimization</li>
        <li>Robust, real-time KPIs</li>
        <li>Business summary reporting</li>
      </ul>
    </div>
  </div>
  
  <div className="feature-box hover-effect">
    <div className="feature-content">
      <div className="feature-image"><div className="border-image"><img src="/img/icon3.png" alt="icon3" /></div></div>
      <div className="feature-title">
        <h2>GROW ASSETS</h2>
        <h3>Optimal Return on Investment</h3>
      </div>
      <ul className="dropdown-list">
        <li>Increased capture + average rates</li>
        <li>Increase training speed and accuracy</li>
        <li>Mitigate displaced revenue</li>
        <li>Contactless bookings</li>
        <li>Immediate business intelligence</li>
        <li>Significantly reduce mistakes</li>
      </ul>
    </div>
  </div>
</div>



{ /* new close*/}

{/* three boxes new */}
            <div className="nthree">
              <div className="nbox01 none">
                <div className="nimagecontet nimagecontet1">
                  <div className="nimg1"><div className="bor3"> <img src="/img/icon1.png" alt="icon1" /></div></div>
                  <div className="ntitle12">
                    <h2>
                    DRIVE SALES
                    </h2>
                    <h3>Increase Revenue</h3>
                    <ul>
                        <li>In-moment daily dashboard</li>
                        <li>Pre-defined product knowledge</li>
                        <li>Auto-waitlist optimization</li>
                        <li>Robust, real-time KPIs</li>
                        <li>Business summary reporting</li>
                      </ul>
                  </div>
                </div>

              </div>
              <div className="nbox01 ntwo2">
                <div className="nimagecontet">
                  <div className="nimg1">   <div className="bor3">   <img src="/img/icon2.png" alt="icon2" /></div></div>
                  <div className="ntitle12">
                    <h2>
                    EQUIP MANAGERS
                    </h2>
                    <h3>Build Control</h3>
                    <ul>
                        <li>In-moment daily dashboard</li>
                        <li>Pre-defined product knowledge</li>
                        <li>Auto-waitlist optimization</li>
                        <li>Robust, real-time KPIs</li>
                        <li>Business summary reporting</li>
                      </ul>
                  </div>
                </div>

              </div>
              <div className="nbox01 nthree3">
                <div className="nimagecontet">
                  <div className="nimg1">
                    <div className="bor3">
                    <img src="/img/icon3.png" alt="icon1" /></div></div>
                  <div className="ntitle12">
                    <h2>
                    GROW ASSETS
                    </h2>
                    <h3>Optimal Return on Investment</h3>
                    <ul>
                        <li>Increased capture + average rates</li>
                        <li>Increase training speed and accuracy</li>
                        <li>Mitigate displaced revenue</li>
                        <li>Contactless bookings</li>
                        <li>Immediate business intelligence</li>
                        <li>Significantly reduce mistakes</li>
                      </ul>
                  </div>
                </div>

              </div>
            </div>
{/* closethree boxes new */}

             { /*  <div className="threecont">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="threin">
                        <h3>DRIVE SALES</h3>
                        <div className="imc">
                          <div className="imgc">
                            <h4>Increase Revenue</h4>
                          </div>
                          <div className="imgg">
                            <img src="/img/icon1.png" alt="icon1" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <ul>
                        <li>
                          Intuitive booking calendar with mobile application
                        </li>
                        <li>Built-in revenue drivers (upsell, retail)</li>
                        <li>Direct online booking</li>
                        <li>Yield management pricing model</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="threin">
                        <h3>EQUIP MANAGERS</h3>
                        <div className="imc imc1">
                          <div className="imgc">
                            <h4>Build Control</h4>
                          </div>
                          <div className="imgg">
                            <img src="/img/icon2.png" alt="icon2" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <ul>
                        <li>In-moment daily dashboard</li>
                        <li>Pre-defined product knowledge</li>
                        <li>Auto-waitlist optimization</li>
                        <li>Robust, real-time KPIs</li>
                        <li>Business summary reporting</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="threin">
                        <h3>GROW ASSETS</h3>
                        <div className="imc imc2">
                          <div className="imgc">
                            <h4>Optimal Return on Investment</h4>
                          </div>
                          <div className="imgg">
                            <img src="/img/icon3.png" alt="icon3" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <ul>
                        <li>Increased capture + average rates</li>
                        <li>Increase training speed and accuracy</li>
                        <li>Mitigate displaced revenue</li>
                        <li>Contactless bookings</li>
                        <li>Immediate business intelligence</li>
                        <li>Significantly reduce mistakes</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="homerow5">
          <div className="container">
            <div className="twoboxes05">
              <div className="boxlef05">
                <h2>Our Products Highlights</h2>
                <p></p>
              </div>
              <div className="boxrig05"></div>
            </div>
             {/* <div className="sliderrow01">
          <Homeproductslider/>
          </div> */}

          {/* */}
            <div className="abfourboxes01">
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Retail1.png" alt="set_up" />
                <h3>SET UP AT EASE</h3>
                <p>
                  5 easy steps to get started, follow the automated flow and
                  enter your valuable data.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Retail2.png" alt="moment" />
                <h3>IN-MOMENT PULSE CHECK</h3>
                <p>
                  Purposeful Performance Indicators will give you real-time
                  access to the instant data, manage your goals and drive
                  success.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Online Booking3.png" alt="booking" />
                <h3>CONTACTLESS BOOKING & PAYMENT</h3>
                <p>
                  Let your and your customers book and control from anywhere
                  with instant confirmation, focus on the arrival experience
                  journey.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Waitlist.png" alt="" />
                <h3>DYNAMIC WAITLIST</h3>
                <p>
                  Always keep track of your customers, no matter how busy you
                  are. Our smart waitlist ensures you are on top of optimizing
                  every bookings.
                </p>
              </div>
            </div>
            <div className="abfourboxes01 mtop20">
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Special Offer.png" alt="assistant" />
                <h3>SPATX ASSISTANT</h3>
                <p>
                  Our SpaTX Assistant centralizes and streamlines your booking
                  process with a sequence of automatic notification.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Retail3.png" alt="train" />
                <h3>TRAIN AND ONBOARD EFFECTIVELY AND EFFICIENTLY</h3>
                <p>
                  SOPs, Products detail and spa knowledges are all in place,
                  provide the clear descriptions and upselling with systematic
                  flows.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Staff.png" alt="team" />
                <h3>PREPARE YOUR TEAM FOR SUCCESS</h3>
                <p>
                  Equip your team for up-selling and retailing with systematic
                  flows for the increased and predictable revenue.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Treatments.png" alt="partnership" />
                <h3>PARTNERSHIP INTEGRATION</h3>
                <p>
                  Open-end possibilities to engage with your specific Hotel
                  operation systems or others operating or marketing tools.
                </p>
              </div>
            </div> {/*  */}
          </div>
        </div>

        <div className="homerow03">
          <div className="container row3text">
            <h2>SELL QUICKER AND MAKE BETTER DECISIONS</h2>
            <button className="btn2" onClick={demobtn}>
              Request a Demo
            </button>
          </div>
        </div>
        <div className="homerow4">
          <div className="bgrow4">
            <div className="container row4text">
              <h2>Our Plans</h2>
              <div className="priceboxes01">
                <div className="pricb1 priceb2">
                  <h3>GROW</h3>
                  <p className="pr">US$960 per year</p>
                  <button className="btn3" onClick={upgradebtn2}>
                    START TRIAL
                  </button>
                  <ul className="list01">
                    <li>10 user accounts</li>
                    <li>Contactless booking</li>
                    <li>Complete booking platform</li>
                    <li>Upsell services and retail</li>
                    <li>Dedicated retail shop</li>
                  </ul>
                  <div className="buttonaling">
                    <button
                      className="tog"
                      onClick={toggleGROW}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        fontSize: "16px",
                      }}
                    >
                      {isGROWToggled ? "Hide All Features" : "See All Features"}
                    </button>
                  </div>
                  {isGROWToggled && (
                    <div style={{ marginTop: "10px" }}>
                      <ul className="list01">
                        <li>Retail inventory management</li>
                        <li>Waitlist management</li>
                        <li>Guest profiling</li>
                        <li>Business pulse check</li>
                        <li>Live reporting suite</li>
                        <li>30+ live KPIs</li>
                        <li>Online payments</li>
                        <li>Integrations</li>
                        <li>Online consultation (Q1, 2025)</li>
                      </ul>
                    </div>
                  )}
                </div>

                <div className="pricb1">
                  <h3>GROUP</h3>
                  <p className="pr pde0" style={{ opacity: 0 }}>
                    Includes 2 users
                  </p>
                  <button className="btn3" onClick={upgradebtn3}>
                    REQUEST CONSULTATION
                  </button>
                  <ul className="list01">
                    <li>Unlimited users</li>
                    <li>Group users</li>
                    <li>Regional users</li>
                    <li>Multi-spa access</li>
                    <li>Real-time group dashboard</li>
                  </ul>
                  <div className="buttonaling">
                    <button
                      className="tog"
                      onClick={toggleGROUP}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        fontSize: "16px",
                      }}
                    >
                      {isGROUPToggled ? "Hide All Features" : "See All Features"}
                    </button>
                  </div>
                  {isGROUPToggled && (
                    <div style={{ marginTop: "10px" }}>
                      <ul className="list01">
                        <li>Live group KPIs</li>
                        <li>Live analytics dashboard</li>
                        <li>Customer demographics</li>
                        <li>Feedback integrations</li>
                        <li>Consultation analysis (Q1, 2025)</li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="homerow6">
          <div className="container">
            <div className="twoboxes06">
              <div className="boxlef05 boxlef06">
                <h2>See real-world results</h2>
                <p>Learn how experts use SpaTX to grow their business </p>
              </div>
              <div className="boxrig05"></div>
            </div>
            <div className="testiblock">
              <Hometestimonials />
            </div>
          </div>
        </div>
        <div className="homrrow7">
          <div className="container">
            <div className="scbox">
              <div className="scle1"></div>
              <div className="rig">
                <h2>
                  Schedule a <br></br>
                  <span>demo today!</span>
                </h2>
                <p>
                  See spa-tx in action and sign up for a personalized demo with
                  one of our technical experts.
                </p>
                <button className="btn2" onClick={demobtn}>
                  Request a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
